<template>
  <div class="confirm-lo">
    <fd-form class="card" @submit.prevent="submitForm">
      <h2 class="px-3 py-4">Confirm Letter of Offer Signed</h2>

      <modal-body class="p-3">
        <fd-date-picker
          v-model="form.signLetterOfferDate"
          class="col-12 px-1 mb-2"
          label="Sign Date"
          name="signLetterOfferDate"
          :validators="[validator.required]"
        >
        </fd-date-picker>
      </modal-body>
      <modal-footer class="row justify-content-end p-2">
        <button type="button" class="btn" @click="$emit('cancel')">
          Cancel
        </button>
        <button class="btn main ml-1">Confirm</button>
      </modal-footer>
    </fd-form>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {
      form: {
        signLetterOfferDate: ""
      },

      validator: {
        required: required
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    submitForm() {
      this.$emit("submit", this.form);
    }
  }
};
</script>

<style lang="scss">
.confirm-lo {
  min-width: 450px;
}
</style>
